.textArea-Input{
    width: 100%;
    height: 129px;
    border-color: #d9d9d9;
}

.star{
    color:#2074C1
}

.letterCount{
    display: block;
    text-align: end;

    span{
        font-style: italic;
        color: #d9d9d9;
        margin-right: 10px;
        font-weight: 400;
    }
}

.drop-down-surveys {
    .ant-select-item-option-content {       
        .ant-row {
            padding-top: 15px;
            padding-bottom: 15px;
            
            &:after {
                content: '';
                position: absolute;
                bottom: 0px;
                border-bottom: 1px solid #ECECEB;
                width: 95%;
                display: block;
            }
        }
    }
}

.analyst-select {
    .ant-select-selector {
        .ant-select-selection-item {
            .ant-select-selection-item-content {
                .ant-space {
                    .ant-typography-secondary {
                        display: none;
                    }
                }
            }
        }
    }
}