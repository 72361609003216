.ImageBankDetailTable, .list-grid-images {
  .image-container {
    width: 100%;
    height: 150px;
    background-color: #ECECEB;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
    &.big {
      height: 243px;
      background-color: #ECECEB;
    }

    img {
      object-fit: contain;
      object-position: center;
      height: auto;
      width: auto;
      max-width: 100%;
      max-height: 100%;

      .center {
        margin: 0 auto
      }
    }
  }

  .DeepMetaphor {
    display: inline-block;
  }
}

.image-preview-bank {
  width: 100%;
  height: 100%;
  max-height: 367px;
  object-fit: contain;
}

.modal-preview-bank {
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-header {
    .ant-modal-title {
      color: #424B5A;
      font-family: "Source Sans Pro";
      font-size: 22px;
      font-weight: 600;
      text-transform: none;
    }
  }

}