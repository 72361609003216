.StatusCircleContainer {
	
    display: flex;
    align-items: center;
    float: left;
    margin-top: 5px;
	
	.StatusCircle {
		display: inline-block;
		border-radius: 50%;
		margin-right: 5px;
		border: 2px solid #2074c13b;
		margin-top: -1px;
	}
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
	.StatusCircle {
		margin-top: -7px;
	}
}