.page-header {
    background-color: white;
    box-shadow: 0 1px 4px 0 rgba(0,21,41,0.12);
    overflow: hidden;
    z-index: 2;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    .logo {
        width: 81px; //120px;
        height: 31px;
        float: left;
        
        .simile-logo {
            height: 15px;
            width: 80px;
        }

      }

    .brand {
        margin-left: 15px;
        position: relative;
        float: left;
        .separator-brand {
            height: 24px;
            width: 1px;
            background-color: #ECECEB;   
            position: absolute;         
            left: 0;
            top: 20px;
        }
        
        .ant-typography {
            margin-left: 16px;
            color: #424B5A;
            font-size: 18px;          
        }
    }

    .menu-navigation {
        color: #424B5A;
        font-family: "Source Sans Pro";
        font-size: 14px;
        letter-spacing: 0;
        line-height: 61px;   
        
        .ant-menu-item-selected {
            font-weight: 600;
            color: #424B5A;
            border-bottom: 3px solid #1765AD;
        }
    }
}