.ImageDetailRow {
    h3 {
      &.ant-typography {
        margin: 0;
        margin: 5px 0 3px;
        font-size: 22px;
      }
    }
  
      .ant-collapse-item {
          > .ant-collapse-header {
              padding: 5px 20px;
          }
          .ant-collapse-content {
              .ant-collapse-content-box {
                  padding: 16px 0;
                  background-color: #ffffff;
              }
          }
      }
      
  }
  