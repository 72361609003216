$metaphors: (
    'balance': #13A8A8,
    'connection': #2F54EB,
    'container': #3696D6,
    'control': #E07100,
    'force': #E73F2B,
    'ideal': #8852C1,
    'journey': #D60E66,
    'nature': #3A7C49,
    'system': #3D5172,
    'transformation': #965353,
    'na': #979797
);

.DeepMetaphor {
    cursor: pointer;
    border: 1px solid;
    padding-left: 9.5px;
    padding-right: 9.5px;
    border-radius: 2px;
    font-size: 12px;
    padding-top: 2px;
    padding-bottom: 2px;
    box-sizing: border-box;
    margin-right: 4px;
    text-align: center;
    text-transform: capitalize;

    @each $name, $color in $metaphors {
        &.metaphor-#{$name} {
            color: $color;
            border-color: $color;
            
            &.active {
                color: white;
                background-color: $color;

                &:hover {   
                    color: white;                              
                    background-color: $color;                           
                }                
            }
            &:hover {             
                background-color: #{$color}15;                             
            }
        }
    }
}

