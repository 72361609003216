.PanelResponseIndex {
	textarea {
		margin-top: 10px;
		&.ant-input {
			padding: 4px 14px;
			resize: none;
		}
		&.ant-input[disabled] {
			background-color: #ffffff;
			cursor: default;
			color: #424B5A;
		}
	}

	.project-info {
		> .ant-col {
			margin-bottom: 9px;
			display: flex;
			align-items: center;
		}
		span {
			&.ant-typography {
				display: block;
			}
		}
	}
}
