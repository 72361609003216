
.response-preview-bg {
    background-color: transparent;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &.inactive {
        width: 0;
        height: 0;
        pointer-events: none;
    }
}
    

.ResponsesPreview {
    position: fixed;
    top: 0;
    width: 260px;
    background-color: #F7F7F7;
    box-shadow: 2px 0 10px 0 rgba(66,75,90,0.1);
    height: 100%;    
    box-sizing: border-box;
    padding-top: 56px;    
    pointer-events: none;
    transition: all 0.2s;
    overflow-y: scroll;

    &.inactive {
        left: -260px;
        pointer-events: none;
    }

    &.active {
        left: 0;
        pointer-events: auto;
    }

    .preview-close-header {
        height: 38px;
        width: 220px;
        background-color: #ECECEB;  
        margin-top: 28px;      
        display: flex;
        box-sizing: border-box;
        padding-left: 8px;
        padding-right: 8px; 
        font-size: 16px;       
        color: #424B5A;
        margin-bottom: 26px;
        cursor: pointer;

        .anticon-left-circle {
            margin-top: 5px;

            &:hover {
                color: #2074C1;
            }
        }

        &:hover {
            .anticon-left-circle {
                color: #2074C1;
            }
        }
    }
}