.response-box {

    &.disabled-response {
       pointer-events: none;
       opacity: 0.7; 
    }

    .disabled-metaphors {
        pointer-events: none;
        opacity: 0.7;         
    }
}