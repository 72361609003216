.aditional-container {
    position: relative;

    .delete-additional {
        position: absolute;
        right: 0;
        bottom: 64px;
        z-index: 1;
        .anticon {
            font-size: 10px;
        }
    }
}