.ResponseDetail {
	position: relative;

	&:hover {	
		border-radius: 4px;
		box-shadow: 0 2px 10px 0 rgba(66,75,90,0.2);
	}

	.info-row {
		margin-top: 27px;
		padding: 9px 20px;
		background-color: rgba(#ECECEB, 0.4);

		.subtitle {
			display: inline-block;
			margin-right: 10px;
		}
	}

	.box-content {
		padding: 16px 18px 22px 22px
	}
	
	.status-row {
		margin-top: 10px;
		padding-left: 20px;
	}

	.image-preview-response {
		width: 195px;
		height: 130px;
		object-fit: cover;
	}

	.starred-icon {
		position: absolute;
		right: 20px;
		top: 18px;
		cursor: pointer;
	}
}