.preview-box {
    border-radius: 2px;
    background-color: #FFFFFF;    
    width: 220px;
    height: auto;
    padding: 8px 15px;
    margin-bottom: 10px;
    color: #424B5A;

    .preview-image {
        margin-top: 6px;
        margin-bottom: 7px;
        overflow: hidden;
        height: 130px;
        width: 190px;
        object-fit: cover;        
    }

    &:hover {
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 2px 10px 0 rgba(66,75,90,0.2)        
    }
}