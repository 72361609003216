.paginationContainer {
  background-color: #f7f7f7;
  margin-top: 20px;
  padding: 16px 25px;
}

.container-metaphor-classification {
  height: calc(100vh - 64px);
  padding: 50px;
}
