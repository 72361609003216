.ImageDescription {

    .answer-description  {
        box-sizing: border-box;
        height: 227px;
        width: 100%;
        border: 1px solid #D9D9D9;
        border-radius: 4px;
        background-color: #FFFFFF;    
        overflow-y: scroll;
        padding: 12px;
        color: #424B5A;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px    
    }

    .img-description-container {
        width: 342px;
        height: 227px;
        overflow: hidden;
        position: relative;

        .link-input {
            position: absolute;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            top: 0;
            left: 0;
            width: 100%;
        }

        .overlay-img-description {
            background-color: rgba(0,0,0,0.7);
            width: 100%;            
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        .img-description {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

        
}