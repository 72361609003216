body {
  background-color: #ECECEB;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Source Sans Pro', sans-serif;
  color:#424B5A;
}

.ant-layout {
  background: #ECECEB;
}

.ant-typography, .ant-radio-wrapper{
  color:#424B5A;
}

.container {
  width: 1120px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-bottom: 100px;
}

.subtitle {
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  color: #979797;
}
.subtitle-large {
  font-size: 16px;
  font-weight: bold;  
}


.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.box-content {
  background: #fff;
  padding: 14px 27px 12px 27px;
  width: 100%;
}

.img-responsive {
  width: 100%;
}

textarea {
  resize: none;
}

.font-weight-normal {
	font-weight: 400;
}

h1.ant-typography, .ant-typography h1 {
  color: #424B5A;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0;
}

h2.ant-typography, .ant-typography h2 {
  color: #424B5A;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0;
}

h4.ant-typography, .ant-typography h4 {
  color: #424B5A;
  font-size: 22px;
  font-weight: bold;
}

.ant-breadcrumb > span:last-child, .ant-breadcrumb > span:last-child a {
  font-weight: 600;
  color: #424B5A;
}

.ant-breadcrumb a, .ant-breadcrumb-separator {
  color: #979797;
}

.logo-main {
  width: 160px
}

.ant-modal-title {
  color: #979797;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  text-transform: uppercase;
}

.modal-image {
  width: 100%;
  height: auto;
}

.clickeable {
  cursor: pointer
}

.toogle-preview-button {
  cursor: pointer;
  position: absolute;
  left: -30px;


  &:hover {
    .anticon {
      svg {
        color: #40a9ff;
      }
    }
  }
}

// .ant-pagination-item {
//   border-radius: 4px;
// }

// .ant-pagination-prev, .ant-pagination-next {
//   border-radius: 4px;
// }

// .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
//   border-radius: 4px;
// }

// .ant-pagination-options-quick-jumper input {
//   border-radius: 4px;
// }

.ant-typography.ant-typography-secondary {
  color: #979797;
}

// .ant-btn-primary {
//   background-color: #2074C1;
//   border-color: #2074C1;

// }

a {
  color: #2074C1;
}

.ant-collapse-borderless {
  background-color: rgba(236, 236, 235, 0.4)
}

.ant-collapse-borderless > .ant-collapse-item {
  border: none;
}

.disabled-container {
  pointer-events: none;
  opacity: 0.7;   
  background-color: #F7F7F7;
}

h3.ant-typography, .ant-typography h3 {
  color: #424B5A
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  background: #177DDC;
  border-color: #177DDC;
}

a {
  h3.ant-typography, .ant-typography h3 {
    color: #1765AD;
    font-weight: bold;
  }

  &:hover {
    h3.ant-typography, .ant-typography h3 {
      color: #177DDC;
    }
  }
}

.ant-breadcrumb a:hover {
  color: #177DDC;
}

.inactive-link {
  &:hover, &:active, &:focus {
    outline: 0 none !important;
  }
}

.ant-breadcrumb-link, .ant-breadcrumb-separator {
  font-weight: 600;
}

.ant-btn-link:hover {
  background-color: transparent;
  opacity: 0.7;
}

.ant-btn-secondary {
  color: #1765AD;
  border-color: #1765AD;
}

.ant-btn-secondary:hover {
  color: rgba(0, 0, 0, 0.65);
  border-color: rgba(0, 0, 0, 0.65);
}

.clean-modal {
  .ant-modal-header, .ant-modal-footer {
    border: none;
  }
}

.modal-small-title {
  .ant-modal-title {
    color: #979797;
    font-family: "Source Sans Pro";
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 14px;
  }
}

.out-of-container {
  margin-left: -26px;
  padding-left: 26px;
  margin-right: -26px;
  padding-right: 26px;
  margin-top: -24px;
  padding-top: 24px;  
}